import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import PostItem from './PostItem';

export const PostImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow-clip-margin: unset;
  position: absolute;
  top: 0;
  left: 0;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #332f29;
  margin: 0px auto;

  @media (min-width: 768px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

// Vertical line between profile pictures in the thread
const ReplyLineContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Dots = styled.div`
  color: grey;
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 10px;
`;

function PostList({ posts, users }) {
  const [likeStatus, setLikeStatus] = useState({});
  const [listExpand, setListExpand] = useState({});
  const [displayedPosts, setDisplayedPosts] = useState(new Set());
  const [allowNSFW, setAllowNSFW] = useState(false);
  const [mutedWords, setMutedWords] = useState([]);

  useEffect(() => {
    const status = {};
    const expandedStatus = {};

    posts.forEach((p) => {
      status[p.PostID] = {
        liked: p.iLiked,
        likeCount: p.LikeCount,
        howlCount: p.howlCount,
        quoteCount: p.quoteCount,
      };
      expandedStatus[p.PostID] = false;
    });
    setLikeStatus(status);
    setListExpand(expandedStatus);
    setDisplayedPosts(new Set());
  }, [posts]);
  
  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('token');
  
      if (token) {
        try {
          await axios.get('/api/v1/validate', {
            headers: { 'Authorization': `Bearer ${token}` }
          }).then(res => {
            if (res.data.Settings) {
              setAllowNSFW(res.data.Settings.AllowNSFW || false);
              setMutedWords(res.data.Settings.MutedWords || []);
            }
          });
        } catch (error) {
          Cookies.remove('token');
        }
      }
    };
  
    fetchData();
  }, []);

  const handleLike = async (postId) => {
    setLikeStatus((prevState) => {
      const newState = { ...prevState };
      newState[postId].liked = !newState[postId].liked;
      newState[postId].likeCount += newState[postId].liked ? 1 : -1;
      return newState;
    });

    await likePost(postId);
  };

  const containsMutedWords = (text) => {
    return Array.isArray(mutedWords) && mutedWords.some(word => text.toLowerCase().includes(word.toLowerCase()));
  };

  useEffect(() => {
    console.log('Posts:', posts);
  }, [posts]);

  const handleExpand = (postId) => {
    setListExpand((prevState) => ({
      ...prevState,
      [postId]: !prevState[postId],
    }));
  };

  async function likePost(postId) {
    try {
      await axios({
        method: 'post',
        url: '/api/v1/make_like',
        data: {
          postId: postId,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer ' + Cookies.get('token'),
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  const renderPostThread = (post, users) => {
    // if (displayedPosts.has(post.PostID)) {
    //   console.log("Skipping displayed post " + post.PostID);
    //   return null;
    // }

    if ((post.NSFW && !allowNSFW) || containsMutedWords(post.Text)) {
      // console.log("Skipping muted post " + post.PostID);
      return null;
    }

    const replies = posts.filter((reply) => reply.ReplyTo === post.PostID);
    const thread = [post, ...replies];
    const startIdx = Math.max(thread.length - 3, 0); // Start index to show last 3 posts
    const visibleThread = thread.slice(startIdx); // Only last 3 posts

    // Mark posts as displayed
    visibleThread.forEach((p) => displayedPosts.add(p.PostID));

    return (
      <React.Fragment key={post.PostID}>
        <ReplyLineContainer>
          {thread.length > 3 && <Dots>...</Dots>} {/* Show dots if there are hidden posts */}
          {visibleThread.map((threadPost, idx) => (
            <PostItem
              key={threadPost.PostID}
              post={threadPost}
              user={users[threadPost.PosterId]}
              liked={likeStatus[threadPost.PostID]?.liked}
              likeCount={likeStatus[threadPost.PostID]?.likeCount}
              handleLikeToggle={() => handleLike(threadPost.PostID)}
              expanded={!!listExpand[threadPost.PostID]}
              handleExpand={() => handleExpand(threadPost.PostID)}
              showVerticalLine={idx < visibleThread.length - 1} // Pass the prop here
              howlCount={likeStatus[threadPost.PostID]?.howlCount}
              quoteCount={likeStatus[threadPost.PostID]?.quoteCount}
            />
          ))}
        </ReplyLineContainer>
        <Divider />
      </React.Fragment>
    );
  };

  return (
    <>
      {posts
        .filter((post) => !post.ReplyTo) // Show only top-level posts initially
        .map((post) => renderPostThread(post, users))}
    </>
  );
}

export default PostList;
