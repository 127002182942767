import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import PostList from './PostList';
import PostBox from './PostBox';
import SearchBar from './SearchBar';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const StyledFeed = styled.div`
  width: 40%;
  margin: 0 auto !important;
  padding: 20px;
  background-color: #06060e;
  border-top: 1px solid #332f29;

  @media (min-width: 768px) {
    border-left: 1px solid #332f29;
    border-right: 1px solid #332f29;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const Feed = () => {
  const [data, setData] = useState({ posts: [], users: {} });
  const [AllowNSFW, setAllowNSFW] = useState(false);

  const [continuesFrom, setContinuesFrom] = useState(null); // Track the last loaded post ID
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('token');
      let myID = -1;

      if (token) {
        try {
          await axios.get('/api/v1/validate', {
            headers: { 'Authorization': `Bearer ${token}` }
          }).then(res => {
            myID = res.status === 200 ? res.data.UserId : -1;
            if (res.data.Settings && res.data.Settings.AllowNSFW) {
              setAllowNSFW(res.data.Settings.AllowNSFW);
            }
          });
        } catch (error) {
          Cookies.remove('token');
          myID = -1;
        }
      }

      try {
        setLoading(true);
        const { data } = await axios.get(`/api/v1/posts?myID=${myID}&continuesFrom=${continuesFrom}&onlyFollow=true`);

        // Update state with new posts and user data
        setData(prevData => ({
          posts: [...prevData.posts, ...data.posts],
          users: { ...prevData.users, ...data.users }
        }));

        // Update the last post ID for the next batch, if there are posts
        if (data.posts.length > 0) {
          setContinuesFrom(data.posts[data.posts.length - 1].thisPostID || data.posts[data.posts.length - 1].PostID);
        }

        setHasMore(data.posts.length > 0);
        setLoading(false);
      } catch (postsError) {
        console.log("Error fetching posts:", postsError);
      }
    };
  
    fetchData();
  }, [continuesFrom]);
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 10 && hasMore && !loading) {
        setContinuesFrom(data.posts.length > 0 ? data.posts[data.posts.length - 1].PostID : null); // Trigger next load
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, loading, data.posts]);

  return (
    <React.Fragment>
      <GlobalStyle />
      <StyledFeed>
        <PostBox AllowNSFW={AllowNSFW} isQuote={false} />
        <PostList posts={data.posts} users={data.users} />
      </StyledFeed>
      <SearchBar />
    </React.Fragment>
  );
};

export default Feed;
