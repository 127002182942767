import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginRegister from './Login';
import UserProfile from './components/UserProfile'
import SinglePost from './components/SinglePost'
import Notifications from './components/Notifications'
import Settings from './components/Settings'
import Explore from './components/Explore';
import HelpMature from './components/HelpMature';
import Search from './components/SearchPage';
import PasswordReset from './components/PasswordReset';
import QuoteList from './components/QuoteList';
import UserListPage from './components/UserList';

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<App/>}/>
      <Route path="/login" element={<LoginRegister/>}/>
      <Route path="/recovery" element={<PasswordReset/>}/>
      {/* <Route path="/:Username/followers" element={<UserListPage/>}/> */}
      {/* <Route path="/:Username/:postId(\\d+)/likes" element={<UserListPage/>}/> */}
      <Route path="/:Username/:postId" element={<SinglePost/>}/>
      {/* <Route path="/:username" element={<UserProfile/>}/> */}
      <Route path="/quotes/:postId(\\d+)" element={<QuoteList/>}/>
      <Route path="/notifications" element={<Notifications/>}/>
      <Route path="/settings" element={<Settings/>}/>
      <Route path="/explore" element={<Explore/>}/>
      <Route path="/mature" element={<HelpMature/>}/>
      <Route path="/search" element={<Search/>}/>
    </Routes>
  </Router>,
  document.getElementById('root')
);
