import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled, { createGlobalStyle } from 'styled-components';
import Cookies from 'js-cookie';
import Navigation from './Navigation';
import MobileNav from './MobileNav';
import PostList from './PostList';
import FilterYap from './FilterYap';
import SearchBar from './SearchBar';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10px;
  gap: 20px;
`;

const StyledFeed = styled.div`
  width: 40%;
  border: 1px solid #332f29;
  padding: 20px;
  background-color: #06060e;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const FollowButton = styled.button`
  background-color: ${props => props.isFollowing ? 'orange' : 'transparent'};
  color: ${props => props.isFollowing ? 'white' : 'orange'};
  border: 1px solid orange;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${props => props.isFollowing ? 'red' : 'transparent'};
    color: ${props => props.isFollowing ? 'white' : 'orange'};
    border-color: ${props => props.isFollowing ? 'red' : 'orange'};
  }
`;

const ProfilePicture = styled.img`
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  object-fit: cover;
  overflow-clip-margin: unset;
  object-position: center;
`;

const DisplayName = styled.h1`
  font-weight: bold;
  margin: 0 0 5px 0;
  color: white;
  text-decoration: none;
  font-size: 2em;
`;

const UserName = styled.span`
  margin: 0 0 10px 0; // Adjust margin as needed
  color: #aaa;
`;

const EditableField = styled.input`
  margin: 10px 0;
  color: #aaa;
`;

const ButtonContainer = styled.div`
  align-self: flex-start;
  margin-left: auto;
  position: relative;
`;

const OptionsButton = styled.button`
  background: transparent;
  border: 1px orange;
  border-radius: 50%;
  color: orange;
  font-size: 1.5em;
  cursor: pointer;
  margin-right: 10px;
  &:hover {
  background: orange;
  }
`;

const OptionsMenu = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #060606e;
  border: 1px solid #332f29;
  broder-radius: 5px;
  padding: 10px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  z-index: 1000;
`;

const MenuItem = styled.button`
  background: none;
  border: none;
  color: white;
  padding: 10px 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
  &:hover {
    background-color: #333;
  }
`;

const EditButton = styled.button`
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
`;

const UserDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px; // Add small left margin
  margin-bottom: 20px;
`;

const BioText = styled.p` // New styled component for bio text
  color: white; // Make bio text white
  margin: 0; // Adjust margin as needed
`;

const MainContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const SortButton = styled.button`
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 1em;
`;

const SortingFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
`;

const FilterButton = styled.button`
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 1em;

  @media (min-width: 769px) {
    display: none;
  }
`;

const SortMenuItem = styled.div`
  padding: 10px;
  color: white;
  cursor: pointer;
  border-bottom: 1px solid #332f29;

  &:hover {
    background-color: #333;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const SortMenu = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  width: 100px;
  background-color: #06060e;
  border: 1px solid #332f29;
  border-radius: 5px;
  z-index: 1000;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #333;
`;

const TabButton = styled.button`
  background-color: ${({ active }) => (active ? 'orange' : 'transparent')};
  color: ${({ active }) => (active ? 'white' : 'orange')};
  border: none;
  border-bottom: ${({ active }) => (active ? '2px solid white' : 'none')};
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.2em;
  flex: 1;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: orange;
    color: white;
  }
`;

function UserProfile() {
  const { username } = useParams();
  const [data, setData] = useState({ user: {}, posts: [], users: {} });
  const [continuesFrom, setContinuesFrom] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({DisplayName: "", Bio: "", UserId: -1});
  const loggedInUsername = Cookies.get('username');
  const authToken = Cookies.get('token');
  const [isFollowing, setIsFollowing] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [userFound, setUserFound] = useState(true);
  const [allowNSFW, setAllowNSFW] = useState(false);
  const [myUserId, setMyUserId] = useState(-1); // My user ID

  // tabs
  const [activeTab, setActiveTab] = useState(0); // 0 = yaps, 1 = likes

  // filtering options
  // todo: when Howling is fully implemented, make sure that howls follow these sorts as well
  const [photosOnly, setPhotosOnly] = useState(false);
  const [noPhotos, setNoPhotos] = useState(false);

  const [videosOnly, setVideosOnly] = useState(false);
  const [noVideos, setNoVideos] = useState(false);

  const [howlsOnly, setHowlsOnly] = useState(false);
  const [noHowls, setNoHowls] = useState(false);

  const [repliesOnly, setRepliesOnly] = useState(false);
  const [noReplies, setNoReplies] = useState(false);

  const [filterPanelVisible, setFilterPanelVisible] = useState(false);

  // sorting options
  const [sortMenuVisible, setSortMenuVisible] = useState(false);
  const [sortMode, setSortMode] = useState(0); // 0 = new, 1 = top, 2 = oldest, 3 = explore

  const filteredPosts = data.posts.filter(post => {
    const hasPhoto = post.Images.some(img => /\.(jpg|jpeg|png|gif)$/i.test(img));
    const hasVideo = post.Images.some(img => /\.(mp4|mov)$/i.test(img));
    const isHowl = /^<Howl \d+>$/.test(post.Text);

    if (photosOnly && !hasPhoto)
      return false;
    if (noPhotos && hasPhoto)
      return false;

    if (videosOnly && !hasVideo)
      return false;
    if (noVideos && hasVideo)
      return false;

    if (howlsOnly && !isHowl)
      return false;
    if (noHowls && isHowl)
      return false;

    if (repliesOnly && (!post.ReplyTo || post.PosterID !== data.user.UserId))
      return false;
    if (noReplies && post.ReplyTo)
      return false;

    return true;
  })
  .sort((a, b) => {
    if (sortMode === 0) {
      return new Date(b.PostTime) - new Date(a.PostTime);
    } else if (sortMode === 1) {
      return b.LikeCount - a.LikeCount;
    } else if (sortMode === 2) {
      return new Date(a.PostTime) - new Date(b.PostTime);
    } else if (sortMode === 3) {
      return a.LikeCount - b.LikeCount; // todo: make this have a proper algorithm at some point
    }
    return 0;
  });

  const handleSortButtonClick = () => {
    setSortMenuVisible(!sortMenuVisible);
  }

  const handleFilterButtonClick = () => {
    setFilterPanelVisible(!filterPanelVisible);
  }

  const handleSortModeChange = (mode) => {
    setSortMode(mode);
    setSortMenuVisible(false);
  }

  const handleFollowToggle = async () => {
    await axios({
      method: 'post',
      url: '/api/v1/make_follow',
      data: {
        username: data.user.Username,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + Cookies.get('token'),
      },
    });

    setIsFollowing(!isFollowing);
  };
  
  const handleMuteUser = async () => {
    await axios({
      method: 'post',
      url: '/api/v1/mute_user',
      data: { userID: data.user.UserId },
      headers: { 'Authorization': `Bearer ${authToken}` },
    });
    setMenuVisible(false);
    alert('User muted successfully');
  };

  const handleBlockUser = async () => {
    await axios({
      method: 'post',
      url: '/api/v1/block_user',
      data: { userID: data.user.UserId },
      headers: { 'Authorization': `Bearer ${authToken}` },
    });
    setMenuVisible(false);
    alert('User blocked successfully');
  };

  const fetchUserData = useCallback(async (myID) => {
    setLoading(true);
    const endpoint = activeTab === 0
      ? `/api/v1/posts?user=${username}&myID=${myID}&page=0&continuesFrom=null`
      : `/api/v1/posts?likes=${username}&myID=${myID}&page=0&continuesFrom=null`;

    axios.get(endpoint)
      .then(res => {
        console.log(res.data);
        if (!res.data.user) {
          setUserFound(false);
        } else {
          setData(res.data);
          setEditableData({
            DisplayName: res.data.user.DisplayName,
            Bio: res.data.user.Bio,
            UserId: res.data.user.UserId,
          });
          setIsFollowing(res.data.user.iFollow);
        }
      })
      .catch(() => {
        setUserFound(false);
      });
  }, [username, activeTab]);

  useEffect(() => {
    const token = Cookies.get('token');
    let myID = -1;
    
    if (token) {
      try {
        axios.get('/api/v1/validate', {
          headers: { 'Authorization': `Bearer ${token}` }
        }).then(res => {
          myID = res.status === 200 ? res.data.UserId : -1;
          // if (res.data.Settings && res.data.Settings.allowNSFW) {
          //   setAllowNSFW(res.data.Settings.allowNSFW);
          // }

          setMyUserId(myID);
    
          fetchUserData(myID);
        });
      } catch (error) {
        Cookies.remove('token');
        myID = -1;
      }
    }
  }, [username, activeTab, fetchUserData]); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const endpoint =
          activeTab === 0
            ? `/api/v1/posts?user=${username}&myID=${myUserId}&continuesFrom=${continuesFrom || 'null'}`
            : `/api/v1/posts?likes=${username}&myID=${myUserId}&continuesFrom=${continuesFrom || 'null'}`;

        const { data } = await axios.get(endpoint);
        // Update state with new posts and user data
        setData(prevData => ({
          posts: [...prevData.posts, ...data.posts],
          users: { ...prevData.users, ...data.users },
          user: data.user,
        }));

        // Update the last post ID for the next batch, if there are posts
        if (data.posts.length > 0) {
          setContinuesFrom(data.posts[data.posts.length - 1].thisPostID || data.posts[data.posts.length - 1].PostID);
        }

        setHasMore(data.posts.length > 0);
        setLoading(false);
      } catch (postsError) {
        console.log("Error fetching posts:", postsError);
      }
    };
  
    fetchData();
  }, [continuesFrom, activeTab, username]);

  useEffect(() => {
    // Reset when username or activeTab changes
    setData({ user: {}, posts: [], users: {} });
    setContinuesFrom(null);
    setHasMore(true);
    setLoading(false); // Ensure no dangling fetch
  }, [activeTab]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 10 && hasMore && !loading) {
        setContinuesFrom(data.posts.length > 0 ? data.posts[data.posts.length - 1].PostID : null); // Trigger next load
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, loading, data.posts]);
  
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

    const handleFileChange = (e) => {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("image", file);
  
      axios.post("https://api.yipyap.io/setpfp", formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${authToken}`
          }
      });
  }
  
    const handleEditToggle = () => {
      if (isEditing) {
        axios({
          method: 'post',
          url: '/api/v1/edit_user',
          data: editableData,
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        })
          // .then(res => {
          //   setData(res.data);
          // })
          .catch(err => console.log(err));
      }
      setIsEditing(!isEditing);
    }

    return (
      <React.Fragment>
        <GlobalStyle />
        <PageContainer>
          <Navigation />
          <StyledFeed>
            <MainContainer>
              {!userFound && (
                <div style={{ margin: '0 auto !important', textAlign: 'center' }}>
                  <h1 style={{ color: '#fff' }}>User not found</h1>
                  <p style={{ color: '#fff' }}>Want to go back <a href='/' style={{ color: 'orange' }}>home</a>?</p>
                </div>
              )}
              {userFound && (
                <>
                  {isEditing 
                    ? <input type="file" onChange={handleFileChange} />
                    : <ProfilePicture src={data.user.ProfilePictureURL} alt={data.user.Username}/>
                  }
                  <UserDetailContainer>
                    {isEditing 
                      ? <EditableField value={editableData.DisplayName} onChange={e => setEditableData({...editableData, DisplayName: e.target.value})}/>
                      : <DisplayName>{editableData.DisplayName}</DisplayName>
                    }
                    <UserName>@{data.user.Username}</UserName>
                    {isEditing 
                      ? <EditableField value={editableData.Bio} onChange={e => setEditableData({...editableData, Bio: e.target.value})}/>
                      : <BioText>{editableData.Bio}</BioText>
                    }
                  </UserDetailContainer>
                  {loggedInUsername !== username && (
                    <ButtonContainer>
                      <OptionsButton onClick={() => setMenuVisible(!menuVisible)}>...</OptionsButton>
                      <OptionsMenu visible={menuVisible}>
                        <MenuItem onClick={handleMuteUser}>Mute User</MenuItem>
                        <MenuItem onClick={handleBlockUser}>Block User</MenuItem>
                      </OptionsMenu>
                      <FollowButton isFollowing={isFollowing} onClick={handleFollowToggle}>
                        {isFollowing ? 'Following' : 'Follow'}
                      </FollowButton>
                    </ButtonContainer>
                  )}
                  {loggedInUsername === username && (
                    <ButtonContainer>
                      <EditButton onClick={handleEditToggle}>
                        {isEditing ? 'Save Changes' : 'Edit Profile'}
                      </EditButton>
                    </ButtonContainer>
                  )}
                </>
              )}

              </MainContainer>

              <TabContainer>
                <TabButton active={activeTab === 0} onClick={() => handleTabChange(0)}>Yaps</TabButton>
                <TabButton active={activeTab === 1} onClick={() => handleTabChange(1)}>Likes</TabButton>
              </TabContainer>

                  <SortingFilterContainer>
                    <div style={{ position: 'relative' }}>
                      <SortButton onClick={handleSortButtonClick}>Sort</SortButton>
                      <SortMenu visible={sortMenuVisible}>
                      <SortMenuItem onClick={() => handleSortModeChange(0)}>New</SortMenuItem>
                      <SortMenuItem onClick={() => handleSortModeChange(1)}>Top</SortMenuItem>
                      <SortMenuItem onClick={() => handleSortModeChange(2)}>Old</SortMenuItem>
                      <SortMenuItem onClick={() => handleSortModeChange(3)}>Explore</SortMenuItem>
                      </SortMenu>
                    </div>
                    <FilterButton onClick={handleFilterButtonClick}>Filter</FilterButton>
                  </SortingFilterContainer>

            <PostList posts={filteredPosts} users={data.users} />
          </StyledFeed>
          <SearchBar />
        </PageContainer>
        <FilterYap
          setPhotosOnly={setPhotosOnly}
          photosOnly={photosOnly}
          setNoPhotos={setNoPhotos}
          noPhotos={noPhotos}

          setVideosOnly={setVideosOnly}
          videosOnly={videosOnly}
          setNoVideos={setNoVideos}
          noVideos={noVideos}

          setHowlsOnly={setHowlsOnly}
          howlsOnly={howlsOnly}
          setNoHowls={setNoHowls}
          noHowls={noHowls}
          
          setRepliesOnly={setRepliesOnly}
          repliesOnly={repliesOnly}
          setNoReplies={setNoReplies}
          noReplies={noReplies}
          />
        <MobileNav />
      </React.Fragment>
    );
  }
  
  export default UserProfile;